<template lang="pug">
main.py-5
  b-container
    b-card.mb-5
      h5.text-uppercase.text-center.text-purple.mt-4
        | ¿Qué Vehículo Quieres Publicar?
      p.text-muted.text-center
        | En {{ $appName }} podras publicar (casi) cualquier vehículo
      form-sell
</template>
<script>
import FormSell from "@/components/sell/FormSell";
export default {
  name: "Sell",
  components: {
    FormSell,
  },
};
</script>
